.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: max-content;

  &.relevance {
    padding: 4px 8px;
    color: $primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.5px;
    border-radius: 4px;
    border: none;
    cursor: default;
    background-color: $gray-border-line;

    i {
      margin-right: 4px;
    }

    &.-planned {
      background-color: $system-warning;
    }

    &.-finished {
      background-color: $gray-border-line;
    }

    &.-cancelled {
      background-color: #f3565699;
    }
  }

  &.inform {
    padding: 4px 6px;
    color: $primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.175px;
    background-color: $gray-background-hover;
    border-radius: 4px;

    i {
      font-size: 16px;
      margin-right: 2px;
    }

    &.-primary {
      background-color: $primary;
      color: $white;
      border-radius: 2px;
      padding: 2px;
      font-size: 8px;
      font-style: normal;
      font-weight: 800;
      line-height: 8px;
      text-transform: uppercase;
    }
  }

  &.statistic {
    border: 1px solid $gray-background-active;
    border-radius: 10px;
    background: $white;
    padding: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .value {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.5px;
      color: $primary;
      align-items: center;
    }

    .title {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.5px;
      color: $gray-content-tertiary;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      i {
        font-size: 12px;
        color: $gray-content-tertiary;
        margin-right: 4px;

        &.-yellow {
          color: $system-warning;
        }

        &.-green {
          color: $system-success;
        }

        &.-red {
          color: $system-cancelled;
        }
      }
    }
  }

  &.filter {
    padding: 4px 12px;
    border-radius: 100px;
    border: 1px solid $gray-border-line;
    height: 26px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.175px;
    color: $primary-content;
    cursor: pointer;
    min-width: fit-content;

    &.selected {
      background-color: $primary;
      color: $white;
    }

    i,
    .icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;
      background-color: $gray-border-line;

      &.-red {
        background-color: $system-cancelled;
      }

      &.-yellow {
        background-color: $system-warning;
      }
    }
  }
}

.filter-wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-start;
  margin-top: 16px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  height: 32px;
  min-height: 32px;

  .category {
    display: flex;
    flex-direction: column;
  }

  &::-webkit-scrollbar {
    height: 6px;
    margin-left: -20px;
  }

  &::-webkit-scrollbar-track {
    background: $white;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-quaternary;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $gray-quaternary;
  }

  @media only screen and (max-width: 767px) {
    overflow-x: auto;
    // height: 32px;
  }
}

@import "node_modules/ngx-toastr/toastr.css";

@import './app/styles/normalize.scss';
@import './app/styles/fonts.scss';
@import './app/styles/typography.scss';
@import './app/styles/variables.scss';
@import './app/styles/animation.scss';
@import './app/styles/input.scss';
@import './app/styles/button.scss';
@import './app/styles/global.scss';
@import './app/styles/card.scss';
@import './app/styles/labels.scss';
@import './app/styles/connected-item.scss';
@import './app/styles/toaster.scss';
@import './app/styles/datepicker.scss';

@import "variables";

.page-content {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  gap: 16px;
  flex: 1;
}

.mobile-page-content {
  padding: 8px 16px 16px 16px;

  @media only screen and (max-width: 767px) {
    // display: flex;
    // flex-direction: column;
    display: contents;
    // overflow-y: auto;
    // padding-bottom: 0;
    // flex-grow: 1;
    // padding-top: 0;
  }

  &_header {
    @media only screen and (max-width: 767px) {
      position: sticky;
      display: block;
      top: 0;
      background-color: $white;
    }
  }
}

.content-card {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: #FFF;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &.-mobile {
    height: auto;
    overflow-x: hidden;
    padding-bottom: 72px;
  }

  .header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 8px 0 8px;

    .header-info-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;

    }

    app-avatar {
      margin-right: 16px;
    }


    .info {
      // margin-left: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $gray-content-tertiary;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.5px;
      flex: 1;

      .name {
        color: $primary-content;
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.5px;
        margin-bottom: 4px;
      }
    }

    .action-section {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      max-width: 182px;
      gap: 4px;

      @media only screen and (max-width: 767px) {
        position: fixed;
        bottom: 0;
        padding: 12px 24px;
        width: 100%;
        max-width: 100%;
        left: 0;
        border-top: 1px solid $gray-border-color;
        background-color: $white;
      }
    }
  }

  .list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      padding: 16px 0;
      border-bottom: 1px solid $gray-background-border;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: var(--spacing-16, 16px);

      &:last-child {
        border-bottom: none;
      }

      span {
        color: $black;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.5px;
        text-align: right;

        &.title {
          color: $gray-content-tertiary;
        }
      }
    }
  }

  .details {
    padding: 0 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .tabs {
      border-bottom: 1px solid $gray-background-border;
    }

    .stats {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      margin-bottom: 12px;
      margin-top: 12px;

      app-skeleton-box.stats-label,
      .label.statistic {
        width: calc(33.333% - 3px);
        box-sizing: border-box;
      }
    }

    &_content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    button.edit {
      width: 100%;
      margin-top: 16px;
    }
  }
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button.tab {
    padding: 12px 0;
    background: none;
    flex: 1;
    color: $gray-content-tertiary;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
    text-align: center;
    display: block;

    &.active {
      padding: 12px 0 9px 0;
      border-bottom: 3px solid $primary;
      color: $primary;
    }
  }
}

.page-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    width: 520px;
    gap: 24px;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    .title {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 100% */
      letter-spacing: -0.5px;
      color: $primary-content;
    }

    .submit-button-wrapper {
      display: contents;

      @media only screen and (max-width: 767px) {
        display: flex;
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        background-color: $white;
        padding: 12px 28px;
        border-top: 1px solid $gray-border-color;
      }
    }
  }
}

.list-items-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  .list-container {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    scrollbar-color: $gray-quaternary $white;
    scrollbar-width: thin;
    padding-right: 4px;
  }

  .list-container::-webkit-scrollbar {
    width: 6px;
    margin-left: 0;
  }

  .list-container::-webkit-scrollbar-track {
    background: $white;
  }

  .list-container::-webkit-scrollbar-thumb {
    background: $gray-quaternary;
    border-radius: 5px;
  }

  .list-container::-webkit-scrollbar-thumb:hover {
    background: $gray-quaternary;
  }

  .list.paginator {
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $gray-border-line;
}

.natodi-datepicker {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div.myDpSelector {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  .myDpSelectorArrow:after, .myDpSelectorArrow:before {
    display: none;
  }

  .myDpMonthYearSelBar {
    position: relative;
    align-items: center;

    .myDpIcon {
      font-size: 16px;
      font-family: 'natodi';
      color: $black;

      &:hover {
        background-color: $white;
      }
    }

    .myDpPrevBtn {
      position: absolute;
      right: 38px;

      button {
        width: 32px;
        height: 32px;
        padding: 8px;
        border-radius: 8px;

        &:hover {
          background-color: $gray-background-hover;
        }

        &::before {
          content: "\e900";
        }
      }
    }

    .myDpNextBtn button {
      width: 32px;
      height: 32px;
      padding: 8px;
      border-radius: 8px;

      &:hover {
        background-color: $gray-background-hover;
      }

      &::before {
        content: "\e900";
        transform: rotate(180deg);
      }
    }
  }

  .myDpMonthYearText {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .myDpHeaderBtn {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.54px;
      color: $black;

      &:hover {
        background-color: $white;
      }
    }
  }

  .myDpCalTable {
    margin-top: 8px;

    .myDpWeekDayTitle {
      color: $gray-content-tertiary;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.5px;
      height: 28px;
    }

    .myDpDaycell {

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: transparent;
      }

      &.myDpDisabled {
        background: none;

        .myDpDayValue {
          color: $gray-quaternary;
        }

        &:hover {
          .myDpDayValue {
            background-color: $white;
          }
        }
      }

      .myDpDayValue {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 32px;
        margin: auto auto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.5px;

        &:hover {
          background-color: $gray-background-hover;
          border-radius: 100px;
        }

        &.myDpMarkCurrDay {
          border-bottom: none;
          background-color: $gray-border-line;
          border-radius: 100px;
          color: $primary;
        }

        &.myDpHighlight {
          color: $primary;
        }
      }

      &.myDpSelectedDay {
        background-color: transparent;

        .myDpDayValue {
          background-color: $accent;
          border-radius: 100px;
        }
      }

      &.myDpPrevMonth {
        opacity: 0;
        pointer-events: none;
      }

      &.myDpNextMonth {
        display: none;
      }
    }
  }

  .myDpMonthTable {
    .myDpMonthcell {
      border-radius: 8px;

      &:hover {
        background-color: $gray-background-hover;
      }

      &.myDpSelectedMonth {
        background-color: $accent;
      }

      .myDpMonthValue {
        border: none;
      }
    }
  }

  .myDpYearTable {
    .myDpYearcell {
      border-radius: 8px;

      &:hover {
        background-color: $gray-background-hover;
      }

      &.myDpSelectedYear {
        background-color: $accent;
      }

      .myDpYearValue {
        border: none;
      }
    }
  }
}

.time-cell {
  border: 1px solid $gray-background-active;
  min-width: 98px;
  max-width: 100px;
  width: 100%;
  border-radius: 100px;
  padding: 8px 12px;
  cursor: pointer;
  text-align: center;
  color: $primary;
  // transition: background-color 0.3s, transform 0.3s;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.5px;

  &:hover {
    background-color: $gray-background-hover;
  }

  &.selected {
    background-color: $accent;
    border: none;
  }
}

@import "variables";

button {
  width: auto;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  i {
    margin: 0 !important;
  }

  &:hover {
    cursor: pointer;
    background-color: $accent-light;
  }

  &.primary {
    background-color: $accent;
    color: $primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    &:hover {
      background-color: $accent-background-hover;
    }

    &.-small {
      padding: 5px 12px;
      height: 32px;
      border-radius: 8px;
      line-height: 22px;
      letter-spacing: 0.175px;
    }

    &.-large {
      padding: 8px 8px;
      height: 40px;
      border-radius: 12px;
      line-height: 18px;
      letter-spacing: -0.42px;
    }

    &.-huge {
      padding: 12px 16px;
      height: 48px;
      border-radius: 10px;
      line-height: 24px;
      letter-spacing: -0.16px;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
    }
  }

  &.outline {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid $gray-background-active;
    background-color: $white;
    color: $primary;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.5px;
    align-items: center;
    display: flex;
    justify-content: center;

    i {
      font-size: 20px;
    }

    &.-no-border {
      border: none;
      font-weight: 500;
      line-height: 24px;
      gap: 6px;

      i {
        font-size: 18px;
      }

      &:hover {
        border-color: $white;
        background-color: $white;
      }
    }

    &:hover {
      border-color: $gray-background-hover;
      background-color: $gray-background-hover;
    }
  }

  &.default {
    padding: 8px;
    border-radius: 10px;
    background-color: $gray-background-hover;
    color: $primary;

    i {
      font-size: 20px;
    }

    &.-small {
      padding: 5px 12px;
      height: 32px;
      border-radius: 8px;
      line-height: 22px;
      letter-spacing: 0.175px;
    }

    &.-large {
      padding: 8px 8px;
      height: 40px;
      border-radius: 12px;
      line-height: 18px;
      letter-spacing: -0.42px;
    }

    &.-huge {
      padding: 12px 16px;
      height: 48px;
      border-radius: 10px;
      line-height: 24px;
      letter-spacing: -0.16px;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
    }
  }

  &.primary-dark {
    background-color: $primary;
    color: $white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -1%;
    border-radius: 10px;
    padding: 12px 8px;

    &.-huge {
      padding: 10px 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.5px;
    }

    &:hover {
      background-color: $dark-background-hover;
    }
  }

  &.destructive {
    padding: 10px 12px;
    border-radius: 10px;
    color: $white;
    background-color: $system-destructive;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.5px;
    align-items: center;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: $system-destructive-hover;
    }
  }

  &.destructive-outline {
    padding: 10px 12px;
    border-radius: 10px;
    color: $system-destructive;
    background-color: $white;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.5px;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid $system-destructive;

    &:hover {
      border-color: $system-destructive-hover;
    }
  }

}

button[type="submit"] {
  &.default {
    padding: 12px 16px;
    width: 100%;
    border-radius: 10px;
    background-color: $primary;
    color: $white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
    align-items: center;
    justify-content: center;

    i {
      font-size: 20px;
      margin-right: 0;
    }
  }

  &:disabled {
    background-color: $gray-background-hover;
    color: $gray-quaternary;
    cursor: default;
  }
}

.pop-up-action-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  z-index: 1050;
  background: #FFFFFF;
  padding: 8px 0;
  border-top: 1px solid $gray-border-line;

  button {
    width: 100%;
    max-width: 472px;
  }
}

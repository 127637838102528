.main-content-container {
  background: $white;
  justify-content: space-between;
  border-radius: 16px;
  overflow: hidden;
  width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;

  @media only screen and (max-width: 767px) {
    width: 100%;
    border-radius: 0;
    gap: 20px;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    .title {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 100% */
      letter-spacing: -1.05px;
      color: $primary-content;
    }
  }

  .service-providers-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    height: 100%;
    width: 100%;

    .search-input {
      width: 100%;
    }
  }

  footer {
    width: 100%;
    position: sticky;
    bottom: -24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: $white;
  }
}
